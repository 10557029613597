<template>
    <div>
        <ts-page-title
            :title="$t('reportPayrollDetail.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('reportPayrollDetail.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card border-0 bg-gray-900">
                                <div class="card-body">
                                    <div class="text-gray-500">
                                        <b>{{
                                            $t(
                                                'reportPayrollDetail.filterCriteria'
                                            )
                                        }}</b>
                                    </div>
                                </div>
                                <div class="widget-list rounded-bottom">
                                    <div class="mb-3 mt-3">
                                        <div class="col-md-12">
                                            <label class="required">{{
                                                $t(
                                                    'reportPayrollDetail.templateName'
                                                )
                                            }}</label>
                                            <select
                                                v-model="template_id"
                                                class="form-control form-select"
                                                :class="{
                                                    'is-invalid':
                                                        errors.has(
                                                            'template_id'
                                                        )
                                                }"
                                                @change="onSelectedTemplate"
                                            >
                                                <option :value="null">
                                                    {{ $t('select') }}
                                                </option>
                                                <option
                                                    v-for="c in reportTemplate"
                                                    :key="c.template_id"
                                                    :value="c.template_id"
                                                >
                                                    {{ c.template_name }}
                                                </option>
                                            </select>
                                            <div
                                                class="text-danger"
                                                v-if="errors.has('template_id')"
                                            >
                                                {{
                                                    errors.first('template_id')
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tw-flex tw--mx-2 mb-3">
                                        <div class="tw-w-1/2 tw-px-2">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label class="required">{{
                                                        $t(
                                                            'reportPayrollDetail.cycleYear'
                                                        )
                                                    }}</label>
                                                    <div>
                                                        <date-Picker
                                                            :value="cycle_year"
                                                            type="year"
                                                            placeholder="Select Year"
                                                            format="yyyy"
                                                            @on-change="
                                                                cycleYearChange
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.has(
                                                                        'cycle_year'
                                                                    )
                                                            }"
                                                        ></date-Picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tw-w-1/2 tw-px-2">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label class="required">{{
                                                        $t(
                                                            'reportPayrollDetail.cycleMonth'
                                                        )
                                                    }}</label>
                                                    <div>
                                                        <date-Picker
                                                            :value="cycle_month"
                                                            type="month"
                                                            placeholder="Select Month"
                                                            format="MM"
                                                            @on-change="
                                                                cycleMonthChange
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.has(
                                                                        'cycle_month'
                                                                    )
                                                            }"
                                                        >
                                                        </date-Picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <RadioGroup v-model="id_type">
                                            <Radio
                                                class="mt-1"
                                                :label="c.value"
                                                border
                                                v-for="(
                                                    c, index
                                                ) in calculateTypes"
                                                :key="index"
                                            >
                                                {{ c.label }}
                                            </Radio>
                                        </RadioGroup>
                                    </div>
                                    <div class="mb-3" v-if="id_type == 1">
                                        <div class="col-md-12">
                                            <label>{{
                                                $t(
                                                    'reportPayrollDetail.orgChartStructure'
                                                )
                                            }}</label>
                                            <Select
                                                v-model="org_chart_structure_id"
                                                filterable
                                                :loading="loading"
                                                class="from-label"
                                                :placeholder="$t('all')"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'org_chart_structure_id'
                                                    )
                                                }"
                                                :clearable="true"
                                                @on-change="onOrgChange"
                                            >
                                                <Option
                                                    v-for="(
                                                        org, index
                                                    ) in orgStructure"
                                                    :value="
                                                        org.org_chart_structure_id
                                                    "
                                                    :key="index"
                                                >
                                                    {{
                                                        org.org_chart_structure
                                                    }}
                                                </Option>
                                            </Select>
                                            <div
                                                class="invalid-feedback"
                                                v-if="
                                                    errors.has(
                                                        'org_chart_structure_id'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        'org_chart_structure_id'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-if="id_type == 2">
                                        <div class="col-md-12">
                                            <label>{{
                                                $t(
                                                    'reportPayrollDetail.shiftName'
                                                )
                                            }}</label>
                                            <Select
                                                v-model="shift_id"
                                                filterable
                                                :loading="loading"
                                                class="from-label"
                                                multiple
                                                :max-tag-count="2"
                                                :placeholder="$t('all')"
                                                @on-change="onShiftChange"
                                            >
                                                <Option
                                                    v-for="(s, index) in shift"
                                                    :value="s.shift_id"
                                                    :key="index"
                                                >
                                                    {{
                                                        s.shift_name +
                                                        '-' +
                                                        s.shift_type
                                                    }}
                                                </Option>
                                            </Select>
                                            <div
                                                class="invalid-feedback"
                                                v-if="errors.has('shift_id')"
                                            >
                                                {{ errors.first('shift_id') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label
                                            class="col-label form-label tw-text-xs"
                                            >{{
                                                $t('payslip.branchNameEn')
                                            }}</label
                                        >
                                        <ts-branch-filter
                                            @filter="
                                                value => (branch_id = value)
                                            "
                                            :isPlaceholder="true"
                                        />
                                    </div>
                                    <div class="row tw-mb-6">
                                        <div class="col-sm-12">
                                            <label>{{
                                                $t(
                                                    'payrollCalculation.employee'
                                                )
                                            }}</label>
                                            <div>
                                                <Select
                                                    v-model="employee_id"
                                                    filterable
                                                    remote
                                                    :remote-method="
                                                        searchEmployee
                                                    "
                                                    :loading="loading"
                                                    class="from-label"
                                                    multiple
                                                    :max-tag-count="2"
                                                    :placeholder="$t('all')"
                                                >
                                                    <Option
                                                        v-for="(
                                                            emp, index
                                                        ) in employeeProfile"
                                                        :value="emp.employee_id"
                                                        :key="index"
                                                    >
                                                        {{
                                                            emp.card_id +
                                                            '-' +
                                                            emp.employee_name_kh +
                                                            '-' +
                                                            emp.employee_name_en
                                                        }}
                                                    </Option>
                                                </Select>
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="
                                                        errors.has(
                                                            'employee_id'
                                                        )
                                                    "
                                                >
                                                    {{
                                                        errors.first(
                                                            'employee_id'
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" v-show="template_id">
                                        <div class="col-sm-12">
                                            <div class="form-group m-b-10">
                                                <form>
                                                    <fieldset>
                                                        <legend>
                                                            {{
                                                                $t(
                                                                    'reportPayrollDetail.reportHeader'
                                                                )
                                                            }}
                                                        </legend>
                                                        <div
                                                            class="row mb-3 mt-2"
                                                            v-if="
                                                                report_header.is_caption1
                                                            "
                                                        >
                                                            <div
                                                                class="form-group col-md-12"
                                                            >
                                                                <label>{{
                                                                    $t(
                                                                        'reportPayrollDetail.captionOne'
                                                                    )
                                                                }}</label>
                                                                <input
                                                                    v-model="
                                                                        report_header.caption1
                                                                    "
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row mb-3"
                                                            v-if="
                                                                report_header.is_caption2
                                                            "
                                                        >
                                                            <div
                                                                class="form-group col-md-12"
                                                            >
                                                                <label>{{
                                                                    $t(
                                                                        'reportPayrollDetail.captionTwo'
                                                                    )
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="
                                                                        report_header.caption2
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row"
                                                            v-if="
                                                                report_header.is_caption3
                                                            "
                                                        >
                                                            <div
                                                                class="form-group col-md-12"
                                                            >
                                                                <label>{{
                                                                    $t(
                                                                        'reportPayrollDetail.captionThree'
                                                                    )
                                                                }}</label>
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        report_header.caption3
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="mb-3 tw-justify-end tw-flex tw-space-x-2"
                                    >
                                        <ts-button
                                            outline
                                            color="success"
                                            @click.prevent="exportExcel"
                                            :waiting="exporting"
                                        >
                                            <i
                                                class="far fa-file-excel"
                                                v-if="!exporting"
                                            ></i>
                                            {{ $t('exportExcel') }}</ts-button
                                        >
                                        <ts-button
                                            color="primary"
                                            @click.prevent="fetchData"
                                            :waiting="viewing"
                                        >
                                            <i
                                                class="far fa-eye"
                                                v-if="!viewing"
                                            ></i>
                                            {{ $t('view') }}</ts-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="overflow-auto" style="max-height: 75vh">
                                <ts-table
                                    :columns="
                                        resources.length > 0
                                            ? ['No', ...Object.values(columns)]
                                            : ['Report viewer']
                                    "
                                    :records="resources"
                                    :loading="viewing"
                                >
                                    <template v-slot="{ record, index }">
                                        <td class="tw-w-1">
                                            {{
                                                (pagination.currentPage - 1) *
                                                    pagination.perPage +
                                                index +
                                                1
                                            }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap"
                                            v-for="colName in columns"
                                            :key="colName"
                                        >
                                            {{
                                                formatCurrencyAggregate(
                                                    colName,
                                                    record[colName]
                                                )
                                            }}
                                        </td>
                                    </template>
                                    <template
                                        v-slot:tfooter
                                        v-if="resources.length > 0"
                                    >
                                        <th
                                            class="tw-bg-gray-100 tw-px-2 tw-py-3 tw-text-left tw-font-bold tw-whitespace-nowrap text-primary tw-bottom-0 tw-sticky"
                                        ></th>
                                        <th
                                            v-for="colName in columns"
                                            :key="colName"
                                            class="tw-bg-gray-100 tw-px-2 tw-py-3 tw-text-left tw-font-bold tw-whitespace-nowrap text-primary tw-bottom-0 tw-sticky"
                                        >
                                            {{ showEachAggregate(colName) }}
                                        </th>
                                    </template>
                                </ts-table>
                            </div>
                            <div
                                class="tw-flex tw-items-center tw-justify-end tw-pt-4"
                            >
                                <ts-i-pagination
                                    v-model="pagination"
                                    @navigate="fetchData"
                                ></ts-i-pagination>
                            </div>
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as FileDownload from 'downloadjs'
import { trim, debounce, find } from 'lodash'
import moment from 'moment'

export default {
    name: 'reportPayrollDetail',
    data () {
        return {
            cycle_year: moment().format('YYYY'),
            cycle_month: moment().format('MM'),
            template_id: null,
            report_header: {
                caption1: null,
                caption1_cell: null,
                caption2: null,
                caption2_cell: null,
                caption3: null,
                caption3_cell: null,
                is_caption1: null,
                is_caption2: null,
                is_caption3: null
            },
            id_type: 1,
            org_chart_structure_id: null,
            shift_id: [],
            employee_id: [],
            loading: false,
            loadingEmployees: false,
            exporting: false,
            viewing: false,
            loadingPreData: false,
            errors: new Errors(),
            calculateTypes: [
                {
                    value: 1,
                    label: 'Organizational Structure'
                },
                {
                    value: 2,
                    label: 'Shift'
                }
            ],
            reportTemplate: [],
            orgStructure: [],
            shift: [],
            employeeProfile: [],
            branch_id: null,
            branches: []
        }
    },
    computed: {
        ...mapState('report/payrollDetail', [
            'resources',
            'pagination',
            'aggregate',
            'columns'
        ]),
        ...mapGetters(['formatNumber']),
        showEachAggregate: vm => colName => {
            const aggregate = find(vm.aggregate, (a, k) => k === colName)

            if (aggregate === undefined) return ''

            return vm.formatNumber(aggregate.value)
        },
        formatCurrencyAggregate: vm => (colName, value) => {
            const aggregate = find(vm.aggregate, (a, k) => k === colName)

            if (aggregate === undefined) return value

            return vm.formatNumber(parseFloat(value))
        }
    },
    methods: {
        ...mapActions('report/payrollDetail', ['getFormViewData']),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.getFormViewData({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        searchEmployee: debounce(async function (query) {
            if (this.employeeProfile.length > 0) {
                var avoidSearch = this.employeeProfile.find(
                    emp =>
                        emp.card_id +
                            '-' +
                            emp.employee_name_kh +
                            '-' +
                            emp.employee_name_en ==
                        query.trim()
                )
                if (avoidSearch) return
            }
            this.fetchResource({
                fnName: 'employeeProfile',
                emp_search: query,
                org_chart_structure_id: this.org_chart_structure_id,
                shift_id: this.shift_id,
                branch_id: this.branch_id
            })
        }, 500),
        fetchData (attributes) {
            this.$store.commit('report/payrollDetail/RESET_STATE')
            this.$store.commit('report/payrollDetail/CLEAR_STATE')
            this.errors = new Errors()
            this.viewing = true
            this.$store
                .dispatch('report/payrollDetail/fetch', {
                    template_id: this.template_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    employee_id: this.employee_id,
                    id_type: this.id_type,
                    shift_id: this.shift_id,
                    org_chart_structure_id: this.org_chart_structure_id,
                    branch_id: this.branch_id,
                    ...attributes
                })
                .then(response => {
                    if(response.records.data.length == 0){
                        this.notice({ type: 'error', text: 'No payroll calculated for current cycle' })
                    }
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.viewing = false))
        },
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.branches = response.data
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        exportExcel () {
            this.errors = new Errors()
            this.exporting = true

            this.$store
                .dispatch('report/payrollDetail/exportExcel', {
                    template_id: this.template_id,
                    cycle_year: this.cycle_year,
                    cycle_month: this.cycle_month,
                    employee_id: this.employee_id,
                    id_type: this.id_type,
                    shift_id: this.shift_id,
                    org_chart_structure_id: this.org_chart_structure_id,
                    branch_id: this.branch_id,
                    report_header: this.report_header
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.exporting = false))
        },
        cycleYearChange (value) {
            this.cycle_year = value
        },
        cycleMonthChange (value) {
            this.cycle_month = value
        },
        async onOrgChange (value) {
            this.employee_id = []
            this.fetchResource({
                fnName: 'employeeProfile',
                org_chart_structure_id: value
            })
        },
        async onShiftChange (value) {
            this.employee_id = []
            this.fetchResource({
                fnName: 'employeeProfile',
                shift_id: value
            })
        },
        onSelectedTemplate () {
            if (!this.template_id) {
                this.report_header = {}
                return
            }
            this.reportTemplate
                .filter(t => t.template_id == this.template_id)
                .map(el => {
                    this.report_header = {
                        caption1: el.caption1,
                        caption1_cell: el.caption1_cell,
                        caption2: el.caption2,
                        caption2_cell: el.caption2_cell,
                        caption3: el.caption3,
                        caption3_cell: el.caption3_cell,
                        is_caption1: el.is_caption1,
                        is_caption2: el.is_caption2,
                        is_caption3: el.is_caption3
                    }
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PAYROLL DETAIL',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource({
                fnName: 'reportTemplate,orgStructure,shift,employeeProfile'
            })
        })
    },
    watch: {
        id_type: function (value) {
            if (value == 1) {
                this.shift_id = null
            } else {
                this.org_chart_structure_id = null
            }
        },
        branch_id: debounce(function (value) {
            this.employee_id = []
            this.fetchResource({
                fnName: 'employeeProfile',
                branch_id: value
            })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('report/payrollDetail/RESET_STATE')
        this.$store.commit('report/payrollDetail/CLEAR_STATE')
        next()
    }
}
</script>
